import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, InterfaceElementName, InterfaceEventName } from '@uniswap/analytics-events'
import { Currency } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { AutoRow } from 'components/Row'
import { COMMON_BASES } from 'constants/routing'
import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList'
import { getTokenAddress } from 'lib/utils/analytics'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { currencyId } from 'utils/currencyId'

const MobileWrapper = styled(AutoColumn)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    display: none;
  `};
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 72px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(23.999998092651367px);
`

const formatAnalyticsEventProperties = (currency: Currency, searchQuery: string, isAddressSearch: string | false) => ({
  token_symbol: currency?.symbol,
  token_chain_id: currency?.chainId,
  token_address: getTokenAddress(currency),
  is_suggested_token: true,
  is_selected_from_list: false,
  is_imported_by_user: false,
  ...(isAddressSearch === false
    ? { search_token_symbol_input: searchQuery }
    : { search_token_address_input: isAddressSearch }),
})

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  searchQuery,
  isAddressSearch,
}: {
  chainId?: number
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
  searchQuery: string
  isAddressSearch: string | false
}) {
  const bases = typeof chainId !== 'undefined' ? COMMON_BASES[chainId] ?? [] : []

  const stables = [
    {
      address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      chainId: 42161,
      decimals: 6,
      isNative: false,
      isToken: true,
      name: 'Circle',
      symbol: 'USDC',
    },
    {
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      chainId: 42161,
      decimals: 6,
      isNative: false,
      isToken: true,
      name: 'Tether',
      symbol: 'USDT',
    },
    {
      address: '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'dForce USD',
      symbol: 'USX',
    },
    {
      address: '0x680447595e8b7b3aa1b43beb9f6098c79ac2ab3f',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'USDD',
      symbol: 'USDD',
    },
    {
      address: '0x35f1c5cb7fb977e669fd244c567da99d8a3a6850',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Usual',
      symbol: 'USD0',
    },
    {
      address: '0x498Bf2B1e120FeD3ad3D42EA2165E9b73f99C1e5',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Curve Stablecoin',
      symbol: 'crvUSD',
    },
    {
      address: '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Magic Internet Money',
      symbol: 'MIM',
    },
    {
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Dai',
      symbol: 'DAI',
    },
    {
      address: '0x004626a008b1acdc4c74ab51644093b155e59a23',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Staked agEUR',
      symbol: 'stEUR',
    },
    {
      address: '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
      chainId: 42161,
      decimals: 6,
      isNative: false,
      isToken: true,
      name: 'Overnight USD',
      symbol: 'USD+',
    },
  ]

  const tbills = [
    {
      address: '0x59D9356E565Ab3A36dD77763Fc0d87fEaf85508C',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Mountain Protocol',
      symbol: 'USDM',
    },
    {
      address: '0x35e050d3c0ec2d29d269a8ecea763a183bdf9a9d',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Ondo Finance',
      symbol: 'USDY',
    },
  ]

  const utilities = [
    {
      address: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Chainlink',
      symbol: 'LINK',
    },
    {
      address: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Uniswap',
      symbol: 'Uni',
    },
    {
      address: '0x912ce59144191c1204e64559fe8253a0e49e6548',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Arbitrum',
      symbol: 'ARB',
    },
    {
      address: '0x9623063377ad1b27544c965ccd7342f7ea7e88c7',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'The Graph',
      symbol: 'GRT',
    },
    {
      address: '0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Lido DAO',
      symbol: 'LDO',
    },
  ]

  const stocks = [
    {
      address: '0x43333771E8d5FD74E5491d10aeAc2BFdC23D19C8',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Dinari - Wrapped GameStop Corp. Class A',
      symbol: 'GME.dw',
    },
    {
      address: '0x407274ABb9241Da0A1889c1b8Ec65359dd9d316d',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Dinari - Wrapped Coinbase Global, Inc. Class A Common Stock',
      symbol: 'wCOIN.d',
    },
    {
      address: '0x2F71B3804a74A013d00065225752dBA9aD061e51',
      chainId: 42161,
      decimals: 18,
      isNative: false,
      isToken: true,
      name: 'Dinari - Wrapped Reddit, Inc.',
      symbol: 'RDDT.dw',
    },
  ]

  return bases.length > 0 ? (
    <MobileWrapper gap="md">
      <div>
        <Text fontWeight={500} fontSize={16}>
          <Trans>Stable</Trans>
        </Text>
      </div>
      <AutoRow gap="4px">
        {stables.map((currency: Currency) => {
          const isSelected = selectedCurrency?.equals(currency)

          return (
            <TraceEvent
              events={[BrowserEvent.onClick, BrowserEvent.onKeyPress]}
              name={InterfaceEventName.TOKEN_SELECTED}
              properties={formatAnalyticsEventProperties(currency, searchQuery, isAddressSearch)}
              element={InterfaceElementName.COMMON_BASES_CURRENCY_BUTTON}
              key={currencyId(currency)}
            >
              <BaseWrapper
                tabIndex={0}
                onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                onClick={() => !isSelected && onSelect(currency)}
                disable={isSelected}
                key={currencyId(currency)}
              >
                {/* <CurrencyLogoFromList currency={currency} /> */}
                <Text fontWeight={500} fontSize={16}>
                  {currency.symbol}
                </Text>
              </BaseWrapper>
            </TraceEvent>
          )
        })}
      </AutoRow>
      <div>
        <Text fontWeight={500} fontSize={16}>
          <Trans>T-Bills</Trans>
        </Text>
      </div>
      <AutoRow gap="4px">
        {tbills.map((currency: Currency) => {
          const isSelected = selectedCurrency?.equals(currency)

          return (
            <TraceEvent
              events={[BrowserEvent.onClick, BrowserEvent.onKeyPress]}
              name={InterfaceEventName.TOKEN_SELECTED}
              properties={formatAnalyticsEventProperties(currency, searchQuery, isAddressSearch)}
              element={InterfaceElementName.COMMON_BASES_CURRENCY_BUTTON}
              key={currencyId(currency)}
            >
              <BaseWrapper
                tabIndex={0}
                onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                onClick={() => !isSelected && onSelect(currency)}
                disable={isSelected}
                key={currencyId(currency)}
              >
                {/* <CurrencyLogoFromList currency={currency} /> */}
                <Text fontWeight={500} fontSize={16}>
                  {currency.symbol}
                </Text>
              </BaseWrapper>
            </TraceEvent>
          )
        })}
      </AutoRow>
      <div>
        <Text fontWeight={500} fontSize={16}>
          <Trans>Utility</Trans>
        </Text>
      </div>
      <AutoRow gap="4px">
        {utilities.map((currency: Currency) => {
          const isSelected = selectedCurrency?.equals(currency)

          return (
            <TraceEvent
              events={[BrowserEvent.onClick, BrowserEvent.onKeyPress]}
              name={InterfaceEventName.TOKEN_SELECTED}
              properties={formatAnalyticsEventProperties(currency, searchQuery, isAddressSearch)}
              element={InterfaceElementName.COMMON_BASES_CURRENCY_BUTTON}
              key={currencyId(currency)}
            >
              <BaseWrapper
                tabIndex={0}
                onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                onClick={() => !isSelected && onSelect(currency)}
                disable={isSelected}
                key={currencyId(currency)}
              >
                {/* <CurrencyLogoFromList currency={currency} /> */}
                <Text fontWeight={500} fontSize={16}>
                  {currency.symbol}
                </Text>
              </BaseWrapper>
            </TraceEvent>
          )
        })}
      </AutoRow>
      <div>
        <Text fontWeight={500} fontSize={16}>
          <Trans>Stocks</Trans>
        </Text>
      </div>
      <AutoRow gap="4px">
        {stocks.map((currency: Currency) => {
          const isSelected = selectedCurrency?.equals(currency)

          return (
            <TraceEvent
              events={[BrowserEvent.onClick, BrowserEvent.onKeyPress]}
              name={InterfaceEventName.TOKEN_SELECTED}
              properties={formatAnalyticsEventProperties(currency, searchQuery, isAddressSearch)}
              element={InterfaceElementName.COMMON_BASES_CURRENCY_BUTTON}
              key={currencyId(currency)}
            >
              <BaseWrapper
                tabIndex={0}
                onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                onClick={() => !isSelected && onSelect(currency)}
                disable={isSelected}
                key={currencyId(currency)}
              >
                {/* <CurrencyLogoFromList currency={currency} /> */}
                <Text fontWeight={500} fontSize={16}>
                  {currency.symbol}
                </Text>
              </BaseWrapper>
            </TraceEvent>
          )
        })}
      </AutoRow>
    </MobileWrapper>
  ) : null
}

/** helper component to retrieve a base currency from the active token lists */
function CurrencyLogoFromList({ currency }: { currency: Currency }) {
  const token = useTokenInfoFromActiveList(currency)

  return <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
}
